import React, { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'
import { ShipupTracking } from '@components/tracking'
import MirrorPageContent from '@components/common/MirrorPageContent'

import s from './OrderTrackingView.module.css'

const OrderTrackingView: FC<{ trackingId: string }> = ({ trackingId }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const metaTitle = 'Order follow Smallable'
  const metaDescription = t('layout:common.metaDescription')
  const canonicalUrl = `https://www.smallable.com/${locale}/parcel-tracking/${trackingId}`
  const alternateUrl = [
    {
      hrefLang: 'de',
      href: `https://www.smallable.com/de/parcel-tracking/${trackingId}`,
    },
    {
      hrefLang: 'en',
      href: `https://www.smallable.com/en/parcel-tracking/${trackingId}`,
    },
    {
      hrefLang: 'es',
      href: `https://www.smallable.com/es/parcel-tracking/${trackingId}`,
    },
    {
      hrefLang: 'fr',
      href: `https://www.smallable.com/fr/parcel-tracking/${trackingId}`,
    },
    {
      hrefLang: 'it',
      href: `https://www.smallable.com/it/parcel-tracking/${trackingId}`,
    },
  ]

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        noindex
        nofollow
        canonical={canonicalUrl}
        languageAlternates={alternateUrl}
        openGraph={{
          type: 'website',
          title: metaTitle,
          description: metaDescription,
          url: canonicalUrl,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />
      <TagGeneric urlp="/parcel-tracking" />
      <TagPageCategory pageCategory="parcel-tracking" />
      <div className={s.container}>
        <ShipupTracking trackingId={trackingId} />
      </div>
      <MirrorPageContent
        offset={0}
        limit={6}
        endpoint={{
          fr: '/content-shipup',
          en: '/content-shipup',
          it: '/content-shipup',
          es: '/content-shipup',
          de: '/content-shipup',
        }}
      />
    </>
  )
}

export default OrderTrackingView
